import "./App.css";
import Home from "./pages";

function App() {
    return (
        <div className="app">
            <Home />
        </div>
    );
}

export default App;
